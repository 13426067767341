@import './app/assets/styles/variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap');

@import '/src/app/assets/styles/components/breakpoints.scss';

* {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    height: fit-content;
}

.container {
    padding-left: 60px;
    padding-right: 60px;
}

.page-content-container {
    padding: 24px;
    min-height: 400px;
    background-color: #fff;
    border-radius: 0px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
        0 3px 1px -2px rgba(0, 0, 0, 0.2);
    margin-bottom: 25px;
}

.word-cloud-container {
    height: 330px;
}

.word-cloud-img {
    width: 100%;
    height: 280px;
    object-fit: contain;
}
.text-center {
    text-align: center !important;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.no-button {
    padding: 0;
    border: 0;
    background: none !important;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-30 {
    margin-bottom: 30px;
}

.normal-label {
    display: block;
    margin-bottom: 6px;
}

.full-width {
    width: 100%;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.button-red {
    background-color: #eb5a46 !important;
    color: #fff !important;
}

.button-yellow {
    background-color: #fc9f19 !important;
    color: #fff !important;
}

.button-blue {
    background-color: #1890ff !important;
    color: #fff !important;
}

.button-green {
    background-color: #61bd4f !important;
    color: #fff !important;
}

.page-heading {
    color: $primary-color;
}

.font-weight-600 {
    font-weight: 600;
}

.cursor-pointer {
    cursor: pointer;
}

.sline-item {
    border-radius: 5px;
    border: 1px solid #d7d7d7 !important;
    box-shadow: 1px 1px 3px #cfcfcf;

    & + .sline-item {
        margin-top: 15px;
    }

    .ant-list-item-meta {
        margin-bottom: 10px;
    }

    .ant-list-item-meta-title > a {
        color: $text-grey;
        transition: all 0.3s;
        font-size: 20px;
    }

    p {
        color: #777;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &:hover {
        background-color: #f9f9f9;
    }

    &.active {
        background-color: #f9f9f9;

        // .ant-list-item-meta-title > a {
        //     color: #fff
        // }

        // p {
        //     color: #f9f9f9;
        // }

        // .ant-list-item-action {
        //     li {
        //         color: #fff;
        //     }
        // }
    }
}

.project-detail-tabs {
    overflow: visible;
    background-color: transparent;

    .ant-tabs-nav {
        border-bottom: 1px solid #d7d7d7;
    }

    .ant-tabs-nav::before {
        display: none;
    }

    .ant-tabs-nav-wrap {
        width: 100%;
        display: inline-block !important;

        .ant-tabs-tab {
            justify-content: center;
            font-size: 15px;
            padding: 10px 24px;
            color: #7b7b7b;
            // border-radius: 37px;
            // background: #f9f9f9;
            // border: 1px solid #d7d7d7;

            & + .ant-tabs-tab {
                margin: 0;
                // margin-left: 10px;
            }
        }
    }

    &.white-bg-tabs {
        & > .ant-tabs-nav {
            .ant-tabs-tab {
                background-color: transparent;
            }
        }
    }

    &.nested-tabs {
        .white-bg-tabs {
            .ant-tabs-tab {
                background: transparent;
            }
        }
    }

    .ant-tabs-tab-active {
        // background-color: $primary-color !important;
        border-bottom: 2px solid $primary-color;
        // border-color: $primary-color;
    }

    .ant-tabs-ink-bar {
        background: none;
    }
}

.d-block {
    display: block;
}

.project-list h4 {
    margin-bottom: 0 !important;
}

.section-heading {
    font-size: 24px;
    font-weight: normal;
    color: $text-grey;
    align-items: center;
    padding-bottom: 12px;
}

.d-flex {
    display: flex;
}

.justify-space-between {
    justify-content: space-between;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.m-auto {
    margin: auto;
}

.height-100vh {
    height: 100vh;
}

.ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}

.ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-left: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
}

.width-100 {
    width: 100%;
}

.btn-lg {
    font-size: 18px;
    height: auto;
    padding: 10px 50px;
    border-radius: 37px;
    font-weight: 600;
    -webkit-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
}

.btn-primary-lg {
    color: #f1f1f1;
    background-color: $primary-color;
}

.btn-default {
    background-color: #f9f9f9;
    color: $text-grey;

    &:hover {
        background-color: #f1f1f1;
        color: $text-grey !important;
        border-color: #d7d7d7 !important;
    }
}

.mt-20 {
    margin-top: 20px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mt-15 {
    margin-top: 15px !important;
}

.mt-30 {
    margin-top: 30px;
}

.mt-50 {
    margin-top: 50px;
}

.btn {
    padding: 6px 16px;
    cursor: pointer;
    border-width: 1px;
    border-radius: 2px;
    font-size: 14px;
    border: 1px solid #d7d7d7;
    border-radius: 30px;
    margin-left: 2px;

    &.btn-outline-primary {
        color: $primary-color;
        border-color: $primary-color;
    }

    &:hover {
        background-color: $primary-color;
        color: #fff;
    }
}

#components-layout-demo-side .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
    background: #fff;
}

.logo {
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        object-fit: contain;
        max-height: 51px;
    }
}

.ant-btn-background-ghost {
    &.ant-btn-primary {
        color: $primary-color;
        border-color: $primary-color;

        &:hover {
            background-color: $primary-color;
            color: #f9f9f9;
        }
    }
}

.ant-btn-primary {
    border-color: $primary-color;
    background-color: $primary-color;

    &:hover,
    &:focus {
        background-color: $dark-primary-color;
        border-color: $dark-primary-color;
        color: #fff !important;
    }
}

.ant-btn:hover,
.ant-btn:focus {
    border-color: $primary-color;
    color: $primary-color;
}

.meta-h4 {
    h4 {
        margin-bottom: 0 !important;
    }
}

.ant-layout {
    background: #fbfbfb;
}

.text-right {
    text-align: right;
}

.ant-layout-sider {
    background-color: $dark-blue-color;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
    background-color: $dark-blue-color;
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 222px;
    line-height: 10px;
    margin-top: 10px;
    list-style-position: inside;
    list-style-type: disc;
}

.ant-submenu-open {
    height: 400px;
    width: 400px;
}

.ant-menu-item-only-child {
    height: 383px !important;
}
.ant-menu-item {
    padding: 0;
}
.save-btn {
    font-size: 16px;
    padding: 9px 43px;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: $primary-color;
}

.color-primary {
    color: $primary-color;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-input-affix-wrapper:hover,
.ant-input:focus,
.ant-input-focused,
.ant-input:hover {
    border-color: $primary-color;
}

.ant-switch-checked {
    background-color: $green-color !important;
}

.ant-switch {
    background-color: #ccc;
}
.placeholder-container {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ant-layout-sider {
    .ant-menu-title-content {
        font-size: 15px;
    }

    .ant-menu-item {
        .ant-menu-item-icon {
            font-size: 21px;
        }
    }
}

.approval-design-td {
    max-width: 140px !important;
    text-align: center;
}

.taglist-popup > .ant-modal-content > .ant-modal-header > .ant-modal-title {
    text-transform: uppercase;
}

.taglist-table {
    .ant-table-thead > tr > th {
        color: #7f7e7e;
        text-transform: uppercase;
        font-size: 13px;
    }
    .ant-table-tbody > tr > td {
        padding: 10px 7px !important;
        color: $text-grey;
        transition: none;
    }
    .ant-table-tbody > tr.ant-table-row:hover > td {
        box-shadow: 2px 1px 2px #e3e3e3;
    }
}

.popup-taglist-table {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    .ant-table-thead > tr > th {
        text-transform: uppercase;
        font-size: 13px;
        background-color: #16aaff;
        color: #fff !important;
        font-weight: bold;
    }
    .ant-table-tbody > tr > td {
        padding: 10px 7px !important;
        color: $text-grey;
        transition: none;
    }
    .ant-table-tbody > tr.ant-table-row:hover > td {
        box-shadow: 2px 1px 2px #e3e3e3;
        font-weight: 400;
        color: #16aaff;
    }
    .ant-table-tbody > tr.ant-table-row:nth-of-type(even) > td {
        background: #f3f3f3;
    }
    .ant-table-tbody > tr.ant-table-row:last-of-type > td {
        border-bottom: 2px solid #16aaff;
    }
}

.ant-table-container table > thead > tr:first-child th:last-child {
    color: #7f7e7e;
}

.ant-table-thead > tr > th {
    color: #7f7e7e !important;
}

.d-inline-block {
    display: inline-block;
}

.alectify-header {
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
    border-bottom: 1px solid #d7d7d7;
    z-index: 9;

    .header-right {
        display: flex;
        align-items: center;
    }
}

.alectify-notification {
    cursor: pointer;
    width: 55px;
    text-align: center;
    position: relative;
    justify-content: center;
    display: flex;
    height: 100%;
    align-items: center;

    .anticon {
        font-size: 25px;
        color: #969696;
    }

    &.active {
        background-color: #f9f9f9;
    }

    .search-tags {
        position: absolute;
        right: 0;
        bottom: -69px;
        background: #fff;
        padding: 0px 10px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.18);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.18);
        padding-bottom: 5px;

        .ant-input {
            width: 250px;
            background-color: #fff;
            border-radius: 30px;
            padding-left: 43px;
            height: 40px;
            box-shadow: 1px 1px 1px #e2e2e2;
            font-size: 15px;
            color: $text-grey;
            background: url('app/assets/images/search-icon.png') no-repeat center left 15px;
            background-size: 15px;

            &:hover,
            &:focus {
                border-color: #48c074;
                box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.1), 0 0 0 3px #e3f7f8;
            }
        }
    }

    &:hover {
        background-color: #f9f9f9;
    }
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon {
    font-size: 21px;
}

.ant-layout-footer {
    background-color: #fff;
    padding: 20px 50px;
    z-index: 9;
    color: $text-grey;
    text-align: center;
}

.ant-pagination-item a:hover {
    color: $primary-color;
}

.ant-pagination-item-active a {
    color: #fff !important;
    background: $primary-color;
    border-color: $primary-color;
}

.sidebar-listing {
    padding: 0 20px;
    margin-left: -20px;
    margin-right: -20px;
    height: calc(100% - 148px);
    ul {
        li {
            display: block;
            margin-bottom: 5px;

            .list-item-container {
                padding: 10px 10px;
                display: flex;
                align-items: center;
                position: relative;
                // border-radius: 0 30px 30px 0;
                border-radius: 4px;
                cursor: pointer;
                justify-content: space-between;

                .list-detail {
                    display: flex;
                    width: calc(100% - 70px);
                    align-items: center;
                }

                .sidebar-list-icon {
                    font-size: 23px;
                    margin-right: 10px;
                    color: #777;
                    vertical-align: middle;
                    // display: none;
                }

                .edit-icon {
                    background: none;
                    outline: none;
                    border: 0;
                    font-size: 18px;
                    line-height: 0;
                    color: #777;
                    width: 22px;
                    height: 22px;
                    border-radius: 50%;
                    text-align: center;
                    cursor: pointer;

                    &:hover {
                        color: #555;
                    }
                }

                .sidebar-list-text {
                    font-size: 0.88rem;
                    color: #5e5e5e;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &:hover {
                    background-color: #f1f1f1;
                }

                &.active {
                    color: #fff;
                    background: $primary-color;
                    box-shadow: 1px 2px 3px #c0baba;

                    .sidebar-list-text,
                    .sidebar-list-icon {
                        color: #fff;
                        font-weight: 600;
                    }

                    .edit-icon {
                        color: #fff;
                    }
                }
            }
        }
    }
}

.ant-layout-content {
    margin: 16px;

    &.content-width-sidebar {
        margin-left: 0;
        margin-top: 0;
        margin-bottom: 0;
    }
}

.ant-steps-item-process {
    .ant-steps-item-icon {
        border-color: $primary-color;
    }

    & > .ant-steps-item-container > .ant-steps-item-icon {
        background-color: $primary-color;
    }
}

.ant-steps-item-finish {
    .ant-steps-item-icon {
        border-color: $primary-color;
        & > .ant-steps-icon {
            color: $primary-color;
        }
    }
}

.ant-steps-item-title {
    font-size: 19px;
}

.fixed-right {
    max-height: calc(100vh - 127px);
    overflow-y: auto;
    overflow-x: hidden;
}

.have-nested-containers {
    .page-content-container {
        box-shadow: none;
        padding: 0;
    }
}

.card-heading {
    margin: 0;
    color: $text-grey;
    font-size: 20px;
    border-bottom: 1px solid #d7d7d7;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.card-heading {
    .ant-collapse-header-text {
        color: $text-grey;
    }
}

.custom-description {
    .ant-empty-description {
        color: #777;
        font-size: 21px;
    }
}

.custom-scroll {
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #eee;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #ddd;
    }
}

.custom-scroll-dark {
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: transparent;
        background-color: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #ddd;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #ddd;
    }
}

.fixed-layout {
    max-height: calc(100vh - 143px);
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 0;

    &::-webkit-scrollbar {
        display: none;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        display: none;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        display: none;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        display: none;
    }
}

.hide-stroke {
    tspan {
        stroke: none;
    }
}

.btn-tab {
    font-size: 14px;
    padding: 6px 14px;
    border-radius: 4px;
    height: auto;
    font-weight: normal;
    font-weight: 600;
    -webkit-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
}

.white-bg-search {
    .search-box .ant-input {
        background-color: #fff;
    }
}

.status-flow {
    display: flex;
    // justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;

    .flow-status-container {
        position: relative;
        width: 200px;
        overflow: hidden;

        .mark-status {
            width: 100px;
            height: 80px;
            background-color: #eee;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: $text-grey;
            border-radius: 4px;

            label {
                font-size: 13px;
            }

            p {
                font-size: 13px;
                margin: 0;
            }

            &.yellow {
                background-color: #f6d22b;
                color: #333;
            }

            &.green {
                background-color: #4dcd73;
                color: #fff;
            }
        }
        &::after {
            position: absolute;
            left: 100px;
            display: block;
            width: 100%;
            height: 5px;
            background: #f0f0f0;
            content: '';
            top: 0;
            bottom: 0;
            margin: auto;
        }

        &:last-child {
            &::after {
                display: none;
            }
        }
    }
}

.alectify-collapse {
    border: 0;
    box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
        0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03), 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
        0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
    overflow: hidden;

    .ant-collapse-header {
        background-color: #fff;
        color: $text-grey !important;
    }

    .ant-collapse-item-active {
        .ant-collapse-header {
            background-color: #fafafa;
        }
    }
}

.packages-collapse {
    .ant-collapse-content > .ant-collapse-content-box {
        padding: 0;
    }
    .ant-collapse-item-active {
        .ant-collapse-header {
            background-color: #fff;
        }
    }
}

.normal-btn {
    font-size: 14px;
    padding: 9px 43px;
    font-weight: normal;
    box-shadow: none;
}

.small-upload-cards {
    .ant-upload-list-picture-card-container,
    .ant-upload-select-picture-card {
        height: 80px;
    }
    .ant-upload-list-picture-card .ant-upload-list-item-file + .ant-upload-list-item-name {
        font-size: 12px;
        bottom: 0;
    }
}

.breakdown-collapse {
    margin-top: 20px;
    .ant-collapse-header {
        padding: 0 !important;
    }
}

.package-tag-container {
    width: 80%;
    margin: auto;
}

.mb-25 {
    margin-bottom: 25px;
}

.no-projects-wrapper {
    height: calc(100% - 200px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.subscription-message {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-empty-description {
        font-size: 18px;
    }
}

.no-projects-empty {
    .ant-empty-image svg {
        width: 250px !important;
        height: 250px !important;
    }
}

.m-0 {
    margin: 0 !important;
}

.project-steps {
    display: flex;
    background-color: #fcfcfc;
    margin-left: -24px;
    margin-right: -24px;
    margin-top: -24px;
    border-bottom: 2px solid #f2f2f2;

    .project-step {
        position: relative;
        padding: 20px 20px;
        font-size: 17px;
        color: #d4d4d4;

        & + .project-step {
            margin-left: 15px;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 100%;
            width: 1em;
            height: 100%;
            background: transparent url('./app/assets/images/wizard-arrow.png') no-repeat center
                center;
            background-size: 100% 100%;
        }

        &.first-step {
            background-color: #50aaf4;
            margin-right: -15px;
            color: #fff;
            font-weight: 700;
        }

        &:first-child::after {
            display: none;
        }

        &.active {
            color: $primary-color;
            font-weight: 600;
        }
    }
}

.step-heading {
    color: $text-grey;
    margin-bottom: 30px;
    justify-content: space-between;
    display: flex;
}

.document-collapse {
    border: 1px solid #d9d9d9;
    box-shadow: none !important;
}

.ant-progress-text {
    color: $text-grey;
}

.ant-progress-status-success .ant-progress-bg {
    background-color: #e37172 !important;
}

.text-grey {
    color: $text-grey;
}

.align-items-center {
    align-items: center;
}

.text-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.text-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.dashboard-card {
    min-height: 160px;
    margin-bottom: 25px;

    .custom-card-heading {
        padding-bottom: 0;
        margin-bottom: 0;
        text-align: center;
        border-bottom: 0;
    }

    .dashboard-card-text {
        font-size: 40px;
        color: $text-grey;
        text-align: center;
        margin: 0;

        span {
            font-size: 30px;
        }
    }

    .dashboard-ratings {
        margin: auto;
        width: 84%;
        margin-top: 15px;

        .rating-block {
            display: block;

            .ant-rate-text {
                color: $text-grey;
            }

            & + .rating-block {
                margin-top: 7px;
            }
        }
    }

    .dashboard-list {
        width: 84%;
        margin: auto;
        margin-top: 15px;

        li {
            font-size: 15px;
            display: flex;
            justify-content: space-between;
            color: $text-grey;

            .left-side {
                display: flex;
                align-items: center;

                span {
                    font-size: 22px;
                    color: yellow;
                    position: relative;
                    z-index: 999;
                    margin-right: 10px;
                    width: 30px;
                    height: 30px;
                    display: inline-block;

                    &.happy {
                        background: url('./app/assets/images/emojis.jpg') no-repeat left -36px top -70px;
                        background-size: 139px;
                    }

                    &.normal {
                        background: url('./app/assets/images/emojis.jpg') no-repeat left -5px top -10px;
                        background-size: 139px;
                    }

                    &.sad {
                        background: url('./app/assets/images/emojis.jpg') no-repeat left -66px top -40px;
                        background-size: 139px;
                    }
                }
            }

            p {
                margin: 0;
                color: $text-grey;
                font-size: 16px;
            }

            & + li {
                margin-top: 10px;
            }
        }
    }
}

.dashbaord-user-sentiments {
    ul {
        li {
            display: block !important;
            text-align: center;

            .sentiment-cover {
                text-align: center;
                display: inline-block;
            }

            .left-side {
                display: block !important;

                span {
                    margin-right: 0px !important;
                    width: 110px !important;
                    height: 110px !important;
                    background-size: 533px;

                    &.happy {
                        background: url('./app/assets/images/emojis.jpg') no-repeat left -188px top -324px !important;
                        background-size: 629px !important;
                    }

                    &.normal {
                        background: url('./app/assets/images/emojis.jpg') no-repeat left -47px top -59px !important;
                        background-size: 629px !important;
                    }

                    &.sad {
                        background: url('./app/assets/images/emojis.jpg') no-repeat left -56px top -455px !important;
                        background-size: 629px !important;
                    }
                }
            }

            p {
                font-size: 21px !important;
                font-weight: 600;
            }
        }
    }
}

.ant-collapse {
    .ant-collapse-item-disabled {
        & > .ant-collapse-header {
            color: rgba(0, 0, 0, 0.5) !important;
            background: #f9f9f9;
        }
    }
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover {
    color: rgba(0, 0, 0, 0.25) !important;
}

.text-size-s {
    font-size: 0.9rem;
}

.chart-wrapper {
    width: 100%;
    display: inline-block;
    overflow: hidden;
}

.main-dashboard {
    .card-heading {
        font-weight: 600;
        color: #000;
    }

    .custom-card-heading {
        color: #555 !important;
    }
}

.dashboard-down-cards {
    .dashboard-list {
        margin-top: 37px;

        li {
            font-size: 20px;
            font-weight: 600;

            p {
                font-size: 20px;
                font-weight: normal;
            }
        }
    }
}

.colorful-card {
    border-radius: 10px;

    .dashboard-card-text {
        font-size: 45px;
        text-align: center;
        margin: 5px;
        line-height: 60px;
    }

    .custom-card-heading {
        font-size: 17px !important;
        font-weight: normal;
        // color: #fff !important;
        text-transform: uppercase;
    }

    &.bg-pink {
        background-color: #ce7bb0;
    }

    &.bg-blue {
        background-color: #1572a1;
    }

    &.bg-brown {
        background-color: #bb6464;
    }

    &.bg-light-blue {
        background-color: #17a2b8;
    }
    &.bg-green {
        background-color: #00e396;
    }
    &.bg-orange {
        background-color: #ef8200;
    }
}

.pac-container:empty {
    box-shadow: none !important;
    display: none !important;
}

.mr-25 {
    margin-right: 25px;
}

.position-relative {
    position: relative;
}

.sidebar-toggler {
    position: absolute;
    right: 0;
    top: 0;
    background: #fff;
    border-radius: 4px;
    padding: 4px;
    cursor: pointer;
    z-index: 99;

    &.open {
        right: unset;
        left: -20px;
        top: 0px;
        padding: 2px;
    }
}

.ml-10 {
    margin-left: 10px !important;
}

.ml-15 {
    margin-left: 15px !important;
}

.ml-20 {
    margin-left: 20px !important;
}

.package-team-members {
    display: flex;
    .circle-icon {
        cursor: pointer;
    }
}

.slider {
    position: relative;
    display: flex;
    height: 100vh;

    .right-arrow {
        position: absolute;
        top: 1550px;
        right: 27%;
        font-size: 384px;
        color: black;
        z-index: 10;
        cursor: pointer;
        user-select: none;
    }

    .left-arrow {
        position: absolute;
        top: 1040px;
        left: -3.2%;
        font-size: 384px;
        color: black;
        z-index: 10;
        cursor: pointer;
        user-select: none;
    }
}

.tagName-master-td {
    max-width: 200px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.table-row-name-last-desc {
    max-width: 150px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.table-row-light {
    background-color: #ffffff;
}
.table-row-dark {
    background-color: #f3dada;
}

.table-row-dark .ant-table-column-sort {
    background-color: #f3dada;
}

.description-td {
    max-width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.description-td-edit {
    max-width: 140px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.gant-hover-container {
    padding: 10px 15px;
    width: 200px;

    .label-container {
        width: 100%;
        display: block;

        span {
            float: right;
        }
    }
}

.switch-body {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: center;

    .map-toggle-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 7px 10px;
        cursor: pointer;
        font-size: 0.8rem;
        color: #777;
        background-color: #f1f4f6;
        transition: all 0.2s ease-in;
        border: 1px solid #d7d7d7;
    }

    .active-toggle {
        background: #16aaff;
        border-color: #16aaff;
        color: #fff;
    }
}

.breadcrumb-with-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.main-upload-btn {
    height: 35px;
    padding: 4px 20px;
    color: #555;

    .anticon {
        font-size: 17px;
    }

    &:hover {
        background-color: $primary-color;
        color: #fff;
    }
}

.grey-row {
    background-color: #f9f9f9;
}

$breakpoints: (
    'xs': 0,
    'sm': 480px,
    'md': 720px,
    'lg': 992px,
    'xl': 1390px,
    // 'xxl': 1254px,
);

@mixin xs {
    @media (min-width: map-get($breakpoints, 'xs')) {
        @content;
    }
}

@mixin sm {
    @media (min-width: map-get($breakpoints, 'sm')) {
        @content;
    }
}
@mixin md {
    @media (min-width: map-get($breakpoints, 'md')) {
        @content;
    }
}
@mixin lg {
    @media (min-width: map-get($breakpoints, 'lg')) {
        @content;
    }
}

@mixin xl {
    @media (min-width: map-get($breakpoints, 'xl')) {
        @content;
    }
}

@mixin xxl {
    @media (min-width: map-get($breakpoints, 'xxl')) {
        @content;
    }
}

@mixin breakpoint($bp: 1232px) {
    @media (min-width: $bp) {
        @content;
    }
}

.calendar-cell {
    @include lg {
        margin-top: 18px;

        // font-size: 11px;
    }
}

.calendar-cell {
    @include xl {
        margin-top: 18px;
        min-width: 30px;
    }
}

.calendar-cell {
    @include xs {
        margin-top: 18px;
        min-width: 30px;
    }
}

.calendar-cell-text {
    @include lg {
        font-size: 11px;
        font-size: 13px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        text-align: left !important;
    }
}

.calendar-cell-text {
    @include xs {
        font-size: 11px;
        font-size: 13px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        text-align: left !important;
    }
}
