.file_upload_wrapper {
    position: relative;
    width: 100%;

    Input {
        cursor: pointer;
        border: none;
        opacity: 0;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1;
        margin: 0;
        display: block;
        padding: 0;
        width: 100%;
        height: 31px;
        border-radius: 0 15px 15px 0;
    }

    &::after {
        content: 'Select your file';
        font-size: 15px;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #f3f8ff;
        padding: 10px 15px;
        width: 100%;
        pointer-events: none;
        z-index: 1;
        height: 30px;
        line-height: 15px;
        color: #999;
        font-weight: 300;
        border-radius: 0 15px 15px 0;
    }
    &::before {
        content: 'upload';
        position: absolute;
        cursor: pointer;
        top: 0;
        right: 0;
        display: inline-block;
        height: 30px;
        background-color: #1890ff;
        color: white;
        font-weight: 700;
        z-index: 2;
        font-size: 12px;
        line-height: 30px;
        padding: 0 15px;
        text-transform: uppercase;
        pointer-events: none;
        border-radius: 0 15px 15px 0;
        transition: 0.5s ease-in-out;
    }
}
