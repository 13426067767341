.information-container {
    padding: 1rem;
    margin-bottom: 2rem;
}

.header {
    font-weight: 500;
    p {
        color: #7f7e7e !important;
    }
}
.card-container {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    width: 99.9%;
    padding: 1rem;
    margin-bottom: 1rem;
}
.site-custom {
    margin-bottom: 10px;
    overflow: hidden;
    background: #fff;
    border: 0px;
    border-radius: 2px;
}

.isolate-box {
    .ant-collapse > .ant-collapse-item {
        border: none !important;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        background-color: #f3efef !important;
    }
}

.contacts {
    display: flex;
    align-items: center;
    justify-content: center;
}
.img-top {
    height: 5rem;
    width: 5rem;
}

.font-style {
    font-weight: 600;
    font-size: 1.1rem;
}

.title-body {
    font-size: 1.4rem;
}
.color {
    background-color: rgb(255, 255, 0);
}

.card-with-border {
    width: 100%;
    padding: 1rem;
    margin-bottom: 1rem;
    border: 1px solid #d7d7d7;
    border-radius: 9px;
    overflow: hidden;

    .heading {
        padding: 0.9rem 1rem;
        background-color: #f9f9f9;
        margin: -1rem;
        margin-bottom: 1rem;
        font-size: 1.2rem;
        border-bottom: 1px solid #d7d7d7;
    }
}

.tag-image {
    height: 215px;
}
