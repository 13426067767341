.player-body {
    .video-box {
        height: 30rem;
    }
    .seek-buttons {
        margin-top: 10px;
        display: flex;
        justify-content: flex-start;

        .right-side-container {
            background: linear-gradient(
                to right bottom,
                rgba(255, 255, 255, 0.7),
                rgba(255, 255, 255, 0.3)
            );
            backdrop-filter: blur(100px);
        }

        .punch_list_body {
            cursor: pointer;
            border-radius: 1rem;
            background: #deecff;
            border: none;
            margin: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
                0 3px 1px -2px rgba(0, 0, 0, 0.2);
            &:hover {
                background: #c6cfff;
            }
        }

        .p-text {
            margin: 0 !important;
            padding: 10px;

            span {
                margin-left: 6px;
                font-weight: 600;
            }
        }
    }
}
