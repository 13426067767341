.chart-tab {
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    padding: 10px;
    // height: 99v;

    .ant-picker-range {
        width: 100%;
    }
}

.apexcharts-menu apexcharts-menu-open .apexcharts-menu-item .exportCSV {
    display: none !important;
}

.analytics-content {
    padding: 15px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
        0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.chart-generator {
    margin-bottom: 30px;
}

.apexchart-container {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
        0 3px 1px -2px rgba(0, 0, 0, 0.2);
    padding: 10px;
}
