.slick-arrow.slick-prev,
.slick-arrow.slick-next {
    width: 47px;
    height: 118px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(15, 23, 30, 0.5);
    opacity: 1;
    transition: opacity 0.25s ease, background-color 0.25s ease;
    z-index: 1;
    color: transparent;
    &:before {
        display: none;
    }
    &:hover,
    &:focus {
        background-color: rgba(15, 23, 30, 0.6);
        .custom-arrows {
            transform: scale(1.3);
        }
    }
}

.custom-arrows {
    z-index: 2;
    color: #fff;
    height: 18px !important;
    font-size: 25px;
    transition: transform 300ms ease-in-out;
}

.thumbnails {
    transition: transform 450ms;
    cursor: pointer;
    object-fit: contain;
    padding: 1px;
    border-radius: 1.3rem;
    &:hover {
        transform: scale(0.8);
    }
}

.video-player-main {
    margin-top: 5rem;
}
