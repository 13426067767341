.site-card-border-less-wrapper {
    padding: 10px;
    background: #ececec;
}

.pdf-viewer {
    height: 77vh;
    width: 72%;
}
.card-viewer {
    height: 77vh;
    width: 28%;
    overflow: auto;
}
.card-select {
    width: 80px;
    color: #fff;
    .ant-select-arrow {
        color: #fff;
    }
}

.card-select-red {
    .ant-select-selector {
        background-color: #eb5a46 !important;
    }
}

.card-select-yellow {
    .ant-select-selector {
        background-color: #fc9f19 !important;
    }
}

.card-select-green {
    .ant-select-selector {
        background-color: #61bd4f !important;
    }
}
.collapse-padding {
    .ant-collapse-content-box {
        padding: 6px;
    }
}
.comment-list {
    width: 100%;
}
.slider-div {
    display: flex;
    font-size: 14px;
}

.ant-slider-step {
    background: lightgray;
}
.ant-slider-handle.ant-tooltip-open {
    border-color: lightgray;
}
.ant-slider-handle {
    border-radius: 30%;
    border: solid 2px lightgray;
    width: 18px;
    height: 18px;
    margin-top: -7px;
}

.ant-slider-dot-active,
.ant-slider-dot,
.ant-slider-track,
.ant-slider:hover .ant-slider-track {
    background-color: lightgray;
    border: 2px solid lightgray;
}
.ant-slider-dot-active {
    border-color: lightgray;
}
.ant-slider-mark-text-active {
    color: rgba(0, 0, 0, 0.45);
}
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: lightgray;
}
.ant-slider-handle:focus {
    border-color: lightgray;
    box-shadow: none;
}
