@import '../../../assets/styles/variables.scss';

.task-inbox-actions {
    border-bottom: 1px solid #d7d7d7;

    .inbox-search {
        padding-left: 32px;
        height: 38px;
        font-size: 14px;
        color: #555;
        background: url('../../images/search-icon.png') no-repeat center left 10px;
        background-size: 14px;
        border: 0;
        border-radius: 4px;
        width: 100%;
        padding-right: 10px;
        outline: none !important;
    }

    .ant-select {
        color: #555;
        box-shadow: none !important;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border-bottom: 0;
        height: 38px;
        border-top: 0;
        border-right: 0;
        box-shadow: none !important;
        border-color: #d7d7d7 !important;
    }

    .ant-select-single .ant-select-selector .ant-select-selection-item {
        line-height: 38px;
    }
}

.task-inbox-list {
    height: calc(100vh - 357px);
    overflow-y: auto;

    ul {
        li {
            padding: 10px 15px;
            border-bottom: 1px solid #d7d7d7;
            cursor: pointer;
            border-left: 5px solid transparent;
            position: relative;

            .task-inbox-header {
                display: flex;
                justify-content: space-between;
                margin-bottom: 3px;
                align-items: center;

                h4 {
                    font-weight: 600;
                    margin: 0;
                    color: #555;
                    font-size: 13px;
                }
                .actions {
                    .notification-time {
                        font-size: 12px;
                        color: #777;
                    }
                    button {
                        font-size: 12px;
                        height: 22px;
                        box-shadow: none;

                        &:hover {
                            background: none !important;
                            color: #16aaff !important;
                            border-color: #16aaff;
                        }
                    }
                }
            }

            p {
                margin: 0;
                color: #555;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 12px;
            }

            &:hover {
                background-color: #e0f3ff;
            }

            &#active {
                border-left: 5px solid $primary-color;
                background-color: #e0f3ff;

                &.unread::before {
                    display: none;
                }
            }

            &.read {
                // background-color: #ededed;
            }

            &.unread::before {
                content: '';
                position: absolute;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: $primary-color;
                left: 3px;
                top: 16px;
            }
        }
    }
}

.row-body {
    margin-top: 10px;

    .group-list-msg-body {
        width: 100%;
        min-width: 790px;
        height: 600px;
        overflow-x: hidden;
        border: none;
    }
}

.notifications-content {
    max-height: calc(100vh - 318px);
    overflow: hidden;
    .notifications-body {
        height: calc(100vh - 424px);
        overflow-y: auto;
        padding-right: 15px;

        .notification {
            border-radius: 4px;
            padding: 15px;
            margin-bottom: 10px;
            border: 1px solid #d7d7d7;

            p {
                margin-bottom: 10px;
            }

            .date {
                font-size: 13px;
                color: #555;
            }
        }
    }
}

.active-inbox-tab {
    background-color: #deecff;
}

.custom-goto-package-button {
    position: absolute;
    top: -47px;
}

.sub-chart-heading {
    margin-left: 20px;
    margin-top: 8px;
    border-bottom: 2px solid #16aaff;
    color: #555 !important;
}

.gantt-chart-card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
    border-radius: 12px;
}

.last-round-card {
    max-height: 60px;
    .last-round-text {
        display: flex;
        justify-content: space-between;
        h4 {
            color: #6c606cf5 !important;
            font-size: 20px;
            font-weight: 500;
        }
        p {
            color: #828282c2 !important;
            font-size: 13px;
            margin: 0 0 5px 0;
            padding: 0;
            font-weight: 600;
        }
    }
}

.last-round-heading {
    color: #6c606cf5 !important;
}

.last-week-summary-card {
    max-height: 80px;
    .last-round-text {
        display: flex;
        justify-content: space-between;
        h4 {
            color: #073956 !important;
            font-size: 20px;
            font-weight: 500;
        }
        p {
            color: #073956 !important;
            font-size: 13px;
            margin: 0 0 5px 0;
            padding: 0;
        }
    }
}

.last-week-summary-heading {
    color: #073956 !important;
    .ant-avatar {
        float: right;
    }
}
.operator-profile-container {
    margin-right: -10px;
}

.card-tab {
    padding: 2rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    margin-bottom: 20px;
    background: 'white';
    padding: 10px;
}
