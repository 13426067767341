.doc-viewer {
    width: 100%;
    padding: 10px 15px;
    border: 1px solid #d7d7d7;
    border-radius: 4px;
    display: flex;
    align-items: center;
    box-shadow: 1px 1px 1px #e9e9e9;
    cursor: pointer;
    transition: all linear 0.3s;
    justify-content: space-between;

    .doc-content {
        flex: 1;
        display: flex;
        align-items: center;
    }

    &:hover {
        background-color: #f9f9f9;
    }

    .icon {
        font-size: 30px;
        color: #48c074;
    }

    h4 {
        margin: 0;
        margin-left: 12px;
        color: #333;
    }
}

.edit-doc-modal {
    padding: 0;

    .ant-modal-body {
        height: calc(100vh - 108px);
        padding: 0;
    }
}

.folder-view {
    overflow: hidden;
    cursor: pointer;
    text-align: center;
    padding: 15px 0;
    padding-top: 11px;
    border-radius: 5px;

    &:hover {
        background: #f9f9f9;
    }

    .icon {
        text-align: center;

        img {
            max-width: 100%;
            height: 50px;
            margin: auto;
        }
    }

    .folder-name {
        padding: 0 7px;
        word-break: break-word;
        margin-top: 8px;

        h4 {
            line-height: 18px;
            margin-bottom: 0;
            color: #555;
        }
    }
}

.upload-main {
    Input {
        cursor: pointer;
        border: none;
        opacity: 0;
        z-index: 99;
        margin: 0;
        display: block;
        padding: 0;
        width: 87px;
        height: 31px;
    }

    &::before {
        content: 'Upload';
        cursor: pointer;
        position: absolute;
        top: 4px;
        right: 90px;
        display: inline-block;
        height: 32px;
        background-color: #1890ff;
        color: white;
        font-weight: 400;
        z-index: 25;
        box-shadow: 0 2px 0 #000002(0 0 0 / 2%);
        font-size: 13px;
        line-height: 33px;
        padding: 0 15px;
        text-align: center;
        pointer-events: none;
        border-radius: 2px;
        transition: 0.5s ease-in-out;
    }
}
th .ant-checkbox-inner{
    border-color: #1890ff !important;

}