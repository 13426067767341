.date-container {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 0px 15px 0px 6px;
    width: 100%;
    border-radius: 10px;
    background-color: white;
}

.ant-picker-calendar-header .ant-picker-calendar-mode-switch {
    display: none;
}

.ant-picker-calendar-date-value {
    text-align: center !important;
}

.ant-picker-calendar-date-content {
    text-align: center !important;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
    padding: 0 !important;
    text-align: center;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
    overflow: hidden;
}

.ant-picker-calendar-header {
    position: absolute;
    top: 40%;
    right: 48px;
}
